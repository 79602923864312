<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
            <template v-if="currentTitle === '写跟进'">
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="记录" prop="record">
                            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ruleForm.record">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="上传图片" prop="imgIds">
                        <div class="tips-container">
                            <div>
                                <div>
                                    温馨提示：最多上传3张图片，图片大小不超过2M，图片格式为jpg，png。
                                </div>
                                <!-- <div>图片尺寸100:100</div> -->
                            </div>
                            <!-- <div v-show="currentState !== 'view' &&
            currentState !== 'deal' &&
            ruleForm.imgIds.length > 0
            " class="delete-icon" @click="deleteHandler">
                                <i class="el-icon-delete"></i>
                            </div> -->
                        </div>
                        <el-tooltip class="item" effect="dark" content="最多上传3张图片！" placement="top">
                            <el-upload :with-credentials="true" :action="uploadPath" list-type="picture-card"
                                :file-list="fileList" :before-upload="beforeAvatarUpload"
                                :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                                :on-preview="handlePictureCardPreview">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </el-tooltip>

                    </el-form-item>
                </el-row>
            </template>
            <template v-if="currentTitle === '编辑信息'">
                <div>
                    <p class="cus_title">基础信息</p>
                    <el-row :gutter="48">
                        <el-col :span="12">
                            <el-form-item label="联系人" prop="userName">
                                <el-input v-model="ruleForm.userName" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="userPhone">
                                <el-input v-model="ruleForm.userPhone" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="48">
                        <el-col :span="12">
                            <el-form-item label="邮箱">
                                <el-input v-model="ruleForm.userMail" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="地址">
                                <el-input v-model="ruleForm.userAddress" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="48">
                        <el-col :span="12">
                            <el-form-item label="客户企业名称" prop="userCompany">
                                <el-input v-model="ruleForm.userCompany" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="企业类型" prop="enterpriseType">
                            <el-select v-model="ruleForm.enterpriseType" filterable placeholder="请选择"
                                style="width: 310px;">
                                <el-option v-for="item in enterpriseTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="48">
                        <el-col :span="12">
                            <el-form-item label="租户类型" prop="userType">
                            <el-select v-model="ruleForm.userType" filterable placeholder="请选择" style="width: 310px;">
                                <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="客户来源" prop="userSource">
                            <el-select v-model="ruleForm.userSource" filterable placeholder="请选择" style="width: 310px;">
                                <el-option v-for="item in userSourceTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <!-- <div>
                    <p class="cus_title">出租信息</p>
                    <el-row :gutter="48">
                        <el-col :span="12">
                            <el-form-item label="项目">
                                <el-select v-model="ruleForm.projectType" filterable placeholder="请选择"
                                    @change="changeProject" style="width: 310px;">
                                    <el-option v-for="item in projectTypeOptions" :key="item.value" :label="item.label"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="楼栋">
                                <el-select v-model="ruleForm.projectBuild" filterable placeholder="请选择"
                                    @change="changeBuild" style="width: 310px;">
                                    <el-option v-for="item in buildTypeOptions" :key="item.value" :label="item.label"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="48">
                        <el-col :span="12">
                            <el-form-item label="房间号" prop="projectRoomNumber">
                                <el-select v-model="ruleForm.projectRoomNumber" value-key="roomId" filterable disabled
                                    placeholder="请选择" multiple style="width: 310px;">
                                    <el-option v-for="item in roomTypeOptions" :key="item.value.roomId"
                                        :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div> -->
                <div>
                    <p class="cus_title">出租信息</p>
                    <el-descriptions title="" direction="vertical" :column="2" border>
                        <el-descriptions-item label="项目">{{ storeName }}</el-descriptions-item>
                        <el-descriptions-item label="楼栋">{{ buildingName }}</el-descriptions-item>
                        <el-descriptions-item label="房间号">{{ roomName }}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </template>
            <template v-if="currentTitle === '转移客户'">
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="接收人" prop="receivePerson">
                            <el-select v-model="ruleForm.receivePerson" value-key="id" filterable placeholder="请选择"
                                style="width: 310px;" @change="getSharePerson">
                                <el-option v-for="item in personTypeOptions" :key="item.value.id" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <template v-if="currentTitle === '更改房源状态'">
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="状态" prop="buildStatus">
                            <el-select v-model="ruleForm.buildStatus" filterable placeholder="请选择"
                                style="width: 310px;">
                                <el-option v-for="item in statusTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <template v-if="currentTitle === '设为预定'">
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="预定原因" prop="bookingDescription">
                            <el-input type="textarea" v-model="ruleForm.bookingDescription"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <template v-if="currentTitle === '申请延期'">
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="延期原因" prop="delayDescription">
                            <el-input type="textarea" v-model="ruleForm.delayDescription"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <el-form-item align="right" style="margin-top: 60px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('save')">提交</el-button>
            </el-form-item>
        </el-form>
        <el-dialog :visible.sync="dialogVisible" :modal="false">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
    </div>
</template>

<script>
import {
    writeFollow,
    getCustomerDatail,
    getStoreList,
    getBuildList,
    getRoomList,
    updateCustomers,
    getSalePersonList,
    moveCustomers,
    changeRoomStatus,
    operateApply
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { envInfo } from "@/constants/envInfo";
export default {
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
        dialogTitle: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            uploadPath:
                envInfo.bgApp.archivePath +
                "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
            fileList: [],
            dialogImageUrl: "",
            dialogVisible: false,
            projectTypeOptions: [],
            buildTypeOptions: [],
            roomTypeOptions: [],
            ruleForm: {
                record: '',
                userName: '',
                userPhone: '',
                userMail: '',
                userAddress: '',
                projectType: {},
                projectBuild: {},
                projectRoomNumber: [],
                receivePerson: '',
                buildStatus: null,
                bookingDescription: '',
                delayDescription: '',
                imgIds: [],
                userCompany:'',
                enterpriseType:'',
                userType:'',
                userSource:''
            },
            storeName: '',
            buildingName: '',
            roomName: '',
            rules: {
                userCompany: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                userType: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                userSource: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                record: [
                    { required: true, message: '必填项', trigger: 'change' },
                    { max: 150, message: '最多输入150个字符', trigger: 'change' }
                ],
                userName: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                userPhone: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                receivePerson: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                buildStatus: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                bookingDescription: [
                    { required: true, message: '必填项', trigger: 'change' },
                    { max: 150, message: '最多输入150个字符', trigger: 'change' }
                ],
                delayDescription: [
                    { required: true, message: '必填项', trigger: 'change' },
                    { max: 150, message: '最多输入150个字符', trigger: 'change' }
                ],
            },
            personTypeOptions: [],
            currentTitle: null,
            statusTypeOptions: [
                {
                    label: '已租',
                    value: 1
                },
                {
                    label: '预定',
                    value: 3
                }
            ],
            enterpriseTypeOptions: [
                {
                    value: '1',
                    label: 'IT'
                }, {
                    value: '2',
                    label: '金融业'
                }, {
                    value: '3',
                    label: '房地产'
                }, {
                    value: '4',
                    label: '商业服务'
                }, {
                    value: '5',
                    label: '运输/物流'
                }, {
                    value: '6',
                    label: '政府'
                }, {
                    value: '7',
                    label: '文化传媒'
                }, {
                    value: '8',
                    label: '医药'
                }, {
                    value: '9',
                    label: '科技'
                }, {
                    value: '10',
                    label: '其他'
                },
            ],
            userTypeOptions: [{
                value: 1,
                label: '个人'
            }, {
                value: 2,
                label: '企业'
            }],
            userSourceTypeOptions: [
                {
                    value: '1',
                    label: '中介渠道'
                }, {
                    value: '2',
                    label: '全民营销'
                }, {
                    value: '3',
                    label: '自拓'
                }
            ],
        }
    },
    methods: {
        getpersonList() {
            getSalePersonList().then(res => {
                res.data.map((item) => {
                    this.personTypeOptions.push({
                        value: item,
                        label: item.employeeName,
                    })
                })
            })
        },
        getSharePerson() {
            console.log(this.ruleForm.receivePerson);
            // this.ruleForm.sharePersonNumber = this.ruleForm.sharePerson.userPhone
            // this.ruleForm.sharePersonWork = this.ruleForm.sharePerson.roleName
        },
        getProjestList() {
            getStoreList().then(res => {
                if (res) {
                    res.map((item) => {
                        this.projectTypeOptions.push({
                            value: item.storeId,
                            label: item.storeName,
                        })
                    })
                    if (this.ruleForm.projectType.value) {
                        this.getBulidData()
                        this.getRoomData()
                    }
                }
            })
        },
        changeProject() {
            console.log('选中项目', this.ruleForm.projectType);
            this.buildTypeOptions = []
            this.ruleForm.projectBuild = {}
            this.roomTypeOptions = []
            this.ruleForm.projectRoomNumber = []
            this.getBulidData()
        },
        getBulidData() {
            getBuildList({
                storeId: this.ruleForm.projectType.value
            }).then(res => {
                console.log(res);
                res.map((item) => {
                    this.buildTypeOptions.push({
                        value: item.buildingId,
                        label: item.buildingName,
                    })
                })
            })
        },
        changeBuild() {
            this.roomTypeOptions = []
            this.ruleForm.projectRoomNumber = []
            this.getRoomData()
            console.log('选中楼栋', this.ruleForm.projectBuild);
        },
        getRoomData() {
            getRoomList({
                buildingId: this.ruleForm.projectBuild.value
            }).then(res => {
                console.log(res);
                this.dataResource = res;
                res.map((item) => {
                    this.roomTypeOptions.push({
                        value: item,
                        label: item.roomName,
                    })
                })
            })
        },
        clickStep(type) {
            if (type == 'close') {
                this.$emit("close", false);
            } else if (type == 'save') {
                console.log(this.currentState, '状态');
                console.log(this.currentTitle, '标识');
                console.log(this.id, 'id');
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        if (this.currentTitle == '写跟进') {
                            writeFollow({
                                customerId: this.id,
                                resource: '后台',
                                description: this.ruleForm.record,
                                followResourceVOS:this.ruleForm.imgIds
                            }).then(res => {
                                console.log(res);
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '操作成功!'
                                    });
                                    this.$emit("close", true)
                                }
                            })
                        } else if (this.currentTitle == '编辑信息') {
                            let data = this.ruleForm.projectRoomNumber
                            let customerRoomVOS = []
                            let projectVOS = []
                            projectVOS = [
                                {
                                    storeId: this.ruleForm.projectType.value,
                                    storeName: this.ruleForm.projectType.label,
                                    buildingId: this.ruleForm.projectBuild.value,
                                    buildingName: this.ruleForm.projectBuild.label,
                                    // floorName: '',
                                    // roomId: '',
                                    // roomName: '',
                                }
                            ]
                            // 如果没选中项目和房间 改变projectVOS格式
                            if (projectVOS.length > 0) {
                                const firstItem = projectVOS[0];
                                const keys = Object.keys(firstItem);
                                const isUndefined = keys.every(key => firstItem[key] === undefined);

                                if (isUndefined) {
                                    projectVOS = [];
                                }
                            }
                            customerRoomVOS = data.map(item => {
                                return {
                                    storeId: this.ruleForm.projectType.value,
                                    storeName: this.ruleForm.projectType.label,
                                    buildingId: this.ruleForm.projectBuild.value,
                                    buildingName: this.ruleForm.projectBuild.label,
                                    floorName: item.floorName,
                                    roomId: item.roomId,
                                    roomName: item.roomName,
                                };
                            });
                            console.log(projectVOS, '项目');
                            console.log(customerRoomVOS, '房间VO');
                            let list = {
                                id: this.id,
                                contacts: this.ruleForm.userName,
                                contactNumber: this.ruleForm.userPhone,
                                address: this.ruleForm.userAddress,
                                email: this.ruleForm.userMail,
                                tenantType: this.ruleForm.userType == 1 ? '个人' : '企业',
                                enterpriseName: this.ruleForm.userCompany,
                                enterpriseType: this.ruleForm.enterpriseType,
                                source: this.ruleForm.userSource,
                                // customerRoomVOS: customerRoomVOS.length == 0 ? projectVOS : customerRoomVOS
                            }
                            console.log(list, '表单');
                            updateCustomers(list).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '编辑操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        } else if (this.currentTitle == '转移客户') {
                            moveCustomers({
                                id: this.id,
                                employeeName: this.ruleForm.receivePerson.employeeName
                            }).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '转移操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        } else if (this.currentTitle == '更改房源状态') {
                            console.log('00', this.id);
                            changeRoomStatus({
                                id: this.id,
                                roomStatus: this.ruleForm.buildStatus
                            }).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '更改操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        } else if (this.currentTitle == '设为预定') {
                            console.log('设为预定');
                            operateApply({
                                customerId: this.id,
                                type: '1',
                                reason: this.ruleForm.bookingDescription
                            }).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        } else if (this.currentTitle == '申请延期') {
                            console.log('申请延期');
                            operateApply({
                                customerId: this.id,
                                type: '2',
                                reason: this.ruleForm.delayDescription
                            }).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        }
                    }
                })
            }
        },
        getDetail(id) {
            getCustomerDatail({ id }).then(res => {
                console.log(res);
                this.ruleForm.userName = res.data.contacts,
                    this.ruleForm.userPhone = res.data.contactNumber,
                    this.ruleForm.userMail = res.data.email,
                    this.ruleForm.userAddress = res.data.address,
                    this.ruleForm.userCompany = res.data.enterpriseName,
                    this.ruleForm.enterpriseType = res.data.enterpriseType,
                    this.ruleForm.userType = res.data.tenantType,
                    this.ruleForm.userSource = res.data.source,
                    this.storeName = res.data.storeName,
                    this.buildingName = res.data.buildingName,
                    this.roomName = res.data.roomName,
                    this.ruleForm.projectType = {
                        value: res.data.customerRoomVOS[0].storeId,
                        label: res.data.customerRoomVOS[0].storeName,
                    },
                    this.ruleForm.projectBuild = {
                        value: res.data.customerRoomVOS[0].buildingId,
                        label: res.data.customerRoomVOS[0].buildingName,
                    }
                let roomList = []
                res.data.customerRoomVOS.forEach(item => {
                    roomList.push(item);
                });
                this.ruleForm.projectRoomNumber = roomList
            })
        },
        beforeAvatarUpload(file) {
            console.log("this.fileList", this.ruleForm.imgIds.length);
            if (this.ruleForm.imgIds.length >= 3) {
                this.$message.error("最多上传3张图片!");
                return false;
            }
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error("上传图片只能是 JPG,PNG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
            }
            const returnFlag = (isJPG || isPNG) && isLt2M;
            return returnFlag;
        },
        handleAvatarSuccess(files) {
            // this.ruleForm.imgIds.push(files[0].fileId);
            console.log(files);
            files.map(item => {
                this.ruleForm.imgIds.push({
                    fileId: item.fileId,
                    fileType: item.contentType ? item.contentType : item.fileType,
                    thumbnailId: "",
                })
            })
        },
        handleRemove(file, fileList) {
            // this.ruleForm.imgIds = fileList.map((item) => item.response[0].fileId);
            console.log(file, 'file');
            this.ruleForm.imgIds = this.ruleForm.imgIds.filter(
                (item) => item.fileId !== file.response[0].fileId
            );
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
        dialogTitle: {
            handler(val) {
                if (!val) return;
                this.currentTitle = val;
                console.log(this.currentTitle, '标识');
                if (val == '编辑信息') {
                    this.getDetail(this.id);
                    this.getProjestList()

                } else if (val == '转移客户') {
                    this.getpersonList()
                }
            },
            immediate: true,
        }
    }
}

</script>

<style lang="less" scoped>
.cus_title {
    margin-top: 16px;
    margin-bottom: 14px;
    color: #909399;
    font-size: 12px;
    font-weight: bold;
    font-family: Noto Sans SC, Noto Sans SC;
}
</style>