var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _vm.currentTitle === "写跟进"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "记录", prop: "record" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 2,
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.ruleForm.record,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "record", $$v)
                                },
                                expression: "ruleForm.record",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "上传图片", prop: "imgIds" } },
                      [
                        _c("div", { staticClass: "tips-container" }, [
                          _c("div", [
                            _c("div", [
                              _vm._v(
                                "\n                                温馨提示：最多上传3张图片，图片大小不超过2M，图片格式为jpg，png。\n                            "
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "最多上传3张图片！",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  "with-credentials": true,
                                  action: _vm.uploadPath,
                                  "list-type": "picture-card",
                                  "file-list": _vm.fileList,
                                  "before-upload": _vm.beforeAvatarUpload,
                                  "on-success": _vm.handleAvatarSuccess,
                                  "on-remove": _vm.handleRemove,
                                  "on-preview": _vm.handlePictureCardPreview,
                                },
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.currentTitle === "编辑信息"
            ? [
                _c(
                  "div",
                  [
                    _c("p", { staticClass: "cus_title" }, [_vm._v("基础信息")]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 48 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "联系人", prop: "userName" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.ruleForm.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userName", $$v)
                                    },
                                    expression: "ruleForm.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "联系电话", prop: "userPhone" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.ruleForm.userPhone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userPhone", $$v)
                                    },
                                    expression: "ruleForm.userPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 48 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "邮箱" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.ruleForm.userMail,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userMail", $$v)
                                    },
                                    expression: "ruleForm.userMail",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "地址" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.ruleForm.userAddress,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userAddress", $$v)
                                    },
                                    expression: "ruleForm.userAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 48 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客户企业名称",
                                  prop: "userCompany",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.ruleForm.userCompany,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userCompany", $$v)
                                    },
                                    expression: "ruleForm.userCompany",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "企业类型",
                                  prop: "enterpriseType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "310px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.ruleForm.enterpriseType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "enterpriseType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.enterpriseType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.enterpriseTypeOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 48 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "租户类型", prop: "userType" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "310px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.ruleForm.userType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "userType", $$v)
                                      },
                                      expression: "ruleForm.userType",
                                    },
                                  },
                                  _vm._l(_vm.userTypeOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客户来源",
                                  prop: "userSource",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "310px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.ruleForm.userSource,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "userSource",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.userSource",
                                    },
                                  },
                                  _vm._l(
                                    _vm.userSourceTypeOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.label,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("p", { staticClass: "cus_title" }, [_vm._v("出租信息")]),
                    _c(
                      "el-descriptions",
                      {
                        attrs: {
                          title: "",
                          direction: "vertical",
                          column: 2,
                          border: "",
                        },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "项目" } },
                          [_vm._v(_vm._s(_vm.storeName))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "楼栋" } },
                          [_vm._v(_vm._s(_vm.buildingName))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "房间号" } },
                          [_vm._v(_vm._s(_vm.roomName))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.currentTitle === "转移客户"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "接收人", prop: "receivePerson" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "310px" },
                                attrs: {
                                  "value-key": "id",
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: { change: _vm.getSharePerson },
                                model: {
                                  value: _vm.ruleForm.receivePerson,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "receivePerson", $$v)
                                  },
                                  expression: "ruleForm.receivePerson",
                                },
                              },
                              _vm._l(_vm.personTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value.id,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.currentTitle === "更改房源状态"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态", prop: "buildStatus" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "310px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.ruleForm.buildStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "buildStatus", $$v)
                                  },
                                  expression: "ruleForm.buildStatus",
                                },
                              },
                              _vm._l(_vm.statusTypeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.currentTitle === "设为预定"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预定原因",
                              prop: "bookingDescription",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              model: {
                                value: _vm.ruleForm.bookingDescription,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "bookingDescription",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.bookingDescription",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.currentTitle === "申请延期"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "延期原因",
                              prop: "delayDescription",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              model: {
                                value: _vm.ruleForm.delayDescription,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "delayDescription",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.delayDescription",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "60px" },
              attrs: { align: "right" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("close")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("save")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, modal: false },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }