<template>
    <div class="app-container">
        <div>
            <div class="content-top">
                <p class="title">客户信息</p>
                <p class="timeStatus" v-if="cusDatailData.roomStatus !== '预定'">{{ cusDatailData.distanceDay }}</p>
                <div class="text" v-for="(item, index) in customerData" :key="index">
                    <span class="text-item" v-for="(j, k) in item.row" :key="k">
                        <span>{{ j.title }}：</span><span> {{ j.text || '-' }}</span>
                    </span>
                </div>
                <div class="btm">
                    <div class="customerbtm bgcolor" @click="goDetails('0')">写跟进</div>
                    <div class="customerbtm" @click="goDetails('1')">编辑信息</div>
                    <div class="customerbtm" @click="goDetails('2')">转移客户</div>
                    <div class="customerbtm" @click="goDetails('3')" v-if="havePermissions.includes(
                    'gcrm$Service$tenantCrmManagement$assignCustomer'
                )
                    ">放入公海</div>
                    <!-- <div v-if=" havePermissions.includes('gcrm$Service$tenantCrmManagement$editInvestmentStatus')" 
                    class="customerbtm boxwidth" @click="goDetails('4')">更改房源状态</div> -->
                    <div class="customerbtm customerbtmStyle" v-if="cusDatailData.bookingFlag">设为预定</div>
                    <div class="customerbtm" @click="goDetails('5')" v-else>设为预定</div>
                    <div class="customerbtm customerbtmStyle" v-if="cusDatailData.approvalFlag">申请延期</div>
                    <div class="customerbtm" @click="goDetails('6')" v-else>申请延期</div>
                </div>
            </div>
            <div class="content-bottom">
                <div class="box"></div>
                <div class="bottom-title">
                    <span v-for="(item, index) in serveList" :key="index"
                        :class="statusIcon === item.status ? 'active-text' : 'title-text'"
                        @click="changeStyle(item.status)"> {{ item.title }} </span>
                </div>
                <div v-if="isShowData">
                    <div v-if="statusIcon == 1">
                        <div class="bottom-content" v-for="(item, index) in recordsVOS" :key="index">
                            <div class="time">
                                <img src="../../../../../../assets/images/customerRM/round.png" alt="">
                                <div class="time-text">{{ item.creationDate }}</div>
                            </div>
                            <div class="serve">
                                <div class="serve-person">操作人：{{ item.createdBy }}</div>
                                <div class="box-width">
                                    <div class="serve-content">
                                        <div class="SC-text">
                                            <p class="SC-title">跟进内容:</p>
                                            <p class="SC-content">{{ item.description }}</p>
                                        </div>
                                        <div class="SC-text">
                                            <p class="SC-title">图片:</p>
                                            <div class="SC-img" v-if="item.followResourceVOS.length !== 0">
                                                <div class="imgList" v-for="(i, j) in item.followResourceVOS" :key="j">
                                                    <!-- <img :src="urlAddress + i.fileId" alt=""> -->
                                                    <el-image style="width: 64px; height: 64px"
                                                        :src="urlAddress + i.fileId"
                                                        :preview-src-list="getSrcList(item.followResourceVOS)">
                                                    </el-image>
                                                </div>
                                            </div>
                                            <div v-else>-</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="statusIcon == 2">
                        <div class="bottom-content" v-for="(item, index) in recordsVOS" :key="index">
                            <div class="time">
                                <img src="../../../../../../assets/images/customerRM/round.png" alt="">
                                <div class="time-text">{{ item.createTime }}</div>
                            </div>
                            <div class="serve">
                                <div class="serve-person">操作人：{{ item.createBy }}</div>
                                <div class="box-width">
                                    <div class="serve-content">
                                        <div class="SC-text">
                                            <p class="SC-title">跟进链接:</p>
                                            <p class="SC-content">
                                                <a :href="url + item.pdfFile" download style="color: #4374FA;">{{ url +
                    item.pdfFile }}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="statusIcon == 3">
                        <div class="bottom-content" v-for="(item, index) in recordsVOS" :key="index">
                            <div class="record_box">
                                <div class="record_time">
                                    <img src="../../../../../../assets/images/customerRM/round2.png" alt="">
                                    <div class="time-text">发起人：{{ item.createdBy }}</div>
                                </div>
                                <div class="record_serve">
                                    <div class="serve_box">
                                        <div class="box_style">
                                            <div class="box_item" v-for="(j, k) in item.approvalRecordsRow" :key="k">
                                                <div class="first_box_item">
                                                    <p> <span class="label">流程：</span>{{ j.processStr }}</p>
                                                    <p class="item_time">{{ j.creationDate }}</p>
                                                </div>
                                                <div><span  class="label">状态：</span>{{ j.statusStr }}</div>
                                                <div  v-if="j.status !== 2"><span  class="label">原因：</span>{{ j.description || "-" }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="time">
                                <img src="../../../../../../assets/images/customerRM/round.png" alt="">
                                <div class="time-text">{{ item.creationDate }}</div>
                            </div>
                            <div class="serve">
                                <div class="box-width">
                                    <div class="serve-content">
                                        <div class="SC-text">
                                            <p v-if="item.status == 3" class="SC-content contentStyle2">{{
                    item.statusStr }}</p>
                                            <p v-else-if="item.status == 2" class="SC-content contentStyle">{{
                    item.statusStr }}</p>
                                            <p v-else class="SC-content">{{ item.statusStr }}</p>
                                            <p class="SC-title describeStyle"
                                                v-if="item.status == 3 && item.description !== null">驳回原因：{{
                    item.description }}</p>
                                            <p class="SC-title describeStyle"
                                                v-if="item.status == 0 && item.description !== null && type == 2">
                                                延期原因：{{ item.description }}</p>
                                            <p class="SC-title describeStyle"
                                                v-if="item.status == 0 && item.description !== null && type == 1">
                                                预定原因：{{ item.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div v-if="statusIcon == 4">
                        <div class="bottom-content" v-for="(item, index) in recordsVOS" :key="index">
                            <div class="record_box">
                                <div class="record_time">
                                    <img src="../../../../../../assets/images/customerRM/round2.png" alt="">
                                    <div class="time-text">发起人：{{ item.createdBy }}</div>
                                </div>
                                <div class="record_serve">
                                    <div class="serve_box">
                                        <div class="box_style">
                                            <div class="box_item" v-for="(j, k) in item.approvalRecordsRow" :key="k">
                                                <div class="first_box_item">
                                                    <p> <span class="label">流程：</span>{{ j.processStr }}</p>
                                                    <p class="item_time">{{ j.creationDate }}</p>
                                                </div>
                                                <div><span  class="label">状态：</span>{{ j.statusStr }}</div>
                                                <div v-if="j.status !== 2"><span  class="label">原因：</span>{{ j.description || "-" }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="time">
                                <img src="../../../../../../assets/images/customerRM/round.png" alt="">
                                <div class="time-text">{{ item.creationDate }}</div>
                            </div>
                            <div class="serve">
                                <div class="box-width">
                                    <div class="serve-content">
                                        <div class="SC-text">
                                            <p v-if="item.status == 3" class="SC-content contentStyle2">{{
                    item.statusStr }}</p>
                                            <p v-else-if="item.status == 2" class="SC-content contentStyle">{{
                    item.statusStr }}</p>
                                            <p v-else class="SC-content">{{ item.statusStr }}</p>
                                            <p class="SC-title describeStyle"
                                                v-if="item.status == 3 && item.description !== null">驳回原因：{{
                    item.description }}</p>
                                            <p class="SC-title describeStyle"
                                                v-if="item.status == 0 && item.description !== null && type == 2">
                                                延期原因：{{ item.description }}</p>
                                            <p class="SC-title describeStyle"
                                                v-if="item.status == 0 && item.description !== null && type == 1">
                                                预定原因：{{ item.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="present" v-else>
                    暂无数据
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px">
            <template slot="title">
                <span>{{ customerDataDialog.dialogTitle }}</span>
            </template>
            <customerViewDialog v-if="dialogVisible" @close="handleClose" :id="customerDataDialog.id"
                :dialogStatus="customerDataDialog.dialogStatus" :dialogTitle="customerDataDialog.dialogTitle">
            </customerViewDialog>
        </el-dialog>
    </div>
</template>

<script>
import customerViewDialog from "./customerViewDialog.vue"
import {
    getCustomerDatail,
    transferCustomers,
    getStatusList
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { envInfo } from "@/constants/envInfo";
import { mapGetters } from "vuex";
export default {
    components: {
        customerViewDialog
    },
    data() {
        return {
            srcList: [],
            statusIcon: 1,
            isShowData: false,
            url: envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fi=",
            urlAddress: envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=",
            serveList: [
                {
                    title: '跟进记录',
                    status: 1
                },
                {
                    title: '报价单',
                    status: 2
                },
                {
                    title: '延期记录',
                    status: 3
                },
                {
                    title: '预定记录',
                    status: 4
                },
            ],
            dialogVisible: false,
            customerDataDialog: {
                dialogStatus: 'add',
                dialogTitle: "写跟进",
            },
            recordsVOS: [],
            customerData: [
                {
                    row: [
                        {
                            title: '客户企业名称',
                            text: '',
                        },
                        {
                            title: '客户编号',
                            text: '',
                        },
                        {
                            title: '租户类型',
                            text: '',
                        },
                        {
                            title: '企业类型',
                            text: '',
                        },
                        {
                            title: '客户来源',
                            text: '',
                        },
                    ]
                },
                {
                    row: [
                        {
                            title: '联系人',
                            text: '',
                        },
                        {
                            title: '联系电话',
                            text: '',
                        },
                        {
                            title: '邮箱',
                            text: '',
                        },
                        {
                            title: '地址',
                            text: '',
                        },
                        {
                            title: '创建时间',
                            text: '',
                        },
                    ]
                },
                {
                    row: [
                        {
                            title: '销售人员',
                            text: '',
                        },
                        {
                            title: '职务',
                            text: '',
                        },
                        {
                            title: '电话',
                            text: '',
                        },
                        {
                            title: '微信号',
                            text: '',
                        },
                    ]
                },
                {
                    row: [
                        {
                            title: '项目',
                            text: '',
                        },
                        {
                            title: '楼栋',
                            text: '',
                        },
                        {
                            title: '房间号',
                            text: '',
                        },
                        {
                            title: '房源状态',
                            text: '',
                        },
                    ]
                },
            ],
            type: null,
            cusDatailData: '',
            fileList: []
        }
    },
    created() {
        this.getDetailList();
    },
    computed: {
        ...mapGetters(["havePermissions"]),
    },
    methods: {
        getSrcList(array = []) {
            console.log(array, 'array');
            let list = []
            array.map((item) => {
                list.push(this.urlAddress + item.fileId)
            })
            console.log(list, 'list');
            return list
        },
        getDetailList() {
            this.getDropList() 
            let id = this.$route.query.id
            getCustomerDatail({ id }).then(res => {
                console.log(res, '详情');
                if (res) {
                    this.cusDatailData = res.data
                    this.customerData[0].row[0].text = res.data.enterpriseName
                    this.customerData[0].row[1].text = res.data.customerCode
                    this.customerData[0].row[2].text = res.data.tenantType
                    this.customerData[0].row[3].text = res.data.enterpriseType
                    this.customerData[0].row[4].text = res.data.source
                    this.customerData[1].row[0].text = res.data.contacts
                    this.customerData[1].row[1].text = res.data.contactNumber
                    this.customerData[1].row[2].text = res.data.email
                    this.customerData[1].row[3].text = res.data.address
                    this.customerData[1].row[4].text = res.data.creationDate
                    this.customerData[2].row[0].text = res.data.employeeName
                    this.customerData[2].row[1].text = res.data.employeeRole
                    this.customerData[2].row[2].text = res.data.employeePhone
                    this.customerData[2].row[3].text = res.data.employeeWxChat
                    this.customerData[3].row[0].text = res.data.storeName
                    this.customerData[3].row[1].text = res.data.buildingName
                    this.customerData[3].row[2].text = res.data.roomName
                    const statusMap = {
                        1: '已租',
                        2: '在谈',
                        3: '预定',
                        4: '取消',
                        5: '其他'
                    };
                    res.data.roomStatus = statusMap[res.data.roomStatus];
                    this.customerData[3].row[3].text = res.data.roomStatus
                    if (this.statusIcon == 1) {
                        if (res.data.followRecordsVOS != null) {
                            this.recordsVOS = res.data.followRecordsVOS
                        } else {
                            this.recordsVOS = []
                        }

                    } else if (this.statusIcon == 2) {
                        if (res.data.quotationVOS != null) {
                            this.recordsVOS = res.data.quotationVOS
                        } else {
                            this.recordsVOS = []
                        }
                    }

                    if (this.recordsVOS.length !== 0) {
                        this.isShowData = true
                    } else {
                        this.isShowData = false
                    }

                }
            })
        },
        getDropList() {
            getStatusList({
                customerId: this.$route.query.id,
                type: this.type
            }).then(res => {
                console.log(res, '下拉数据');
                if (res) {
                    this.recordsVOS = res.data
                    if (this.recordsVOS.length !== 0) {
                        this.isShowData = true
                    } else {
                        this.isShowData = false
                    }
                }
            })
        },
        changeStyle(state) {
            console.log(state);
            this.statusIcon = state
            this.isShowData = false
            if (state == 3) {
                this.type = 2
                this.getDropList()
            } else if (state == 4) {
                this.type = 1
                this.getDropList()
            } else {
                this.getDetailList();
            }
        },
        handleClose(freshFlag) {
            this.dialogVisible = false
            freshFlag && this.getDetailList();
        },
        goDetails(type) {
            if (type == '0') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "写跟进";
            } else if (type == '1') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "edit";
                this.customerDataDialog.dialogTitle = "编辑信息";
            } else if (type == '2') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "转移客户";
            } else if (type == '3') {
                this.$confirm(
                    '确定放入公海吗？',
                    this.$t("commons.warning"),
                    {
                        confirmButtonText: this.$t("commons.confirm"),
                        cancelButtonText: this.$t("commons.cancel"),
                        type: "warning",
                    }
                ).then(() => {
                    transferCustomers({
                        id: this.$route.query.id,
                        status: 0, //放入公海：0，分配：1
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                            this.$router.go(-1)
                        } else if (res.code == 500) {
                            this.$message({
                                type: 'error',
                                message: res.msg
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                })
            } else if (type == '4') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "更改房源状态";
            } else if (type == '5') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "设为预定";
            } else if (type == '6') {
                this.dialogVisible = true
                this.customerDataDialog.id = this.$route.query.id;
                this.customerDataDialog.dialogStatus = "add";
                this.customerDataDialog.dialogTitle = "申请延期";
            }
        }
    }
}

</script>

<style lang="less" scoped>
.record_box {
    .record_time {
        display: flex;
        align-items: center;

        img {
            width: 18px;
            height: 18px;
            margin-top: 8px;
        }

        .time-text {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #909399;
            font-size: 14px;
            margin-left: 14px;
            margin-top: 8px;
        }
    }

    .record_serve {
        border-left: 1px dashed #DCDFE5;
        margin-left: 9px;
        margin-top: 8px;
        .serve_box {
            padding: 8px 24px;
            .box_style {
                background-color: #F8F8F8;
                padding-left: 28px;
                padding-right: 48px;
                .box_item {
                    padding: 13px 0;
                    >:nth-child(1){
                      color: #606266;;
                      font-size: 14px;
                      font-weight: bold;
                      height: 20px;
                      font-family: Noto Sans SC, Noto Sans SC;
                    }
                    >:nth-child(2){
                        color: #1A4CEC;
                        font-size: 14px;
                        font-weight: bold;
                        height: 20px;
                        font-family: Noto Sans SC, Noto Sans SC;
                    }
                    >:nth-child(3){
                        color: #606266;
                        font-size: 14px;
                        height: 20px;
                        font-family: Noto Sans SC, Noto Sans SC;
                    }
                    .first_box_item {
                        display: flex;
                        justify-content: space-between;
                        .item_time {
                            color: #606266;
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                    .label {
                        color: #909399;
                        font-size: 14px;
                    }
                    
                }
            }
        }
    }
}

.present {
    margin-top: 144px;
    margin-bottom: 140px;
    text-align: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #33333360;
}

.box {
    height: 24px;
}

.app-container {
    background-color: #f1f6fd;
}

.content-top {
    height: 300px;
    background-color: #fff;
    margin-bottom: 16px;
    padding-left: 32px;

    .timeStatus {
        width: 125px;
        height: 24px;
        line-height: 24px;
        border-radius: 2px 2px 2px 2px;
        padding: 2px 8px;
        background: #FEFBF2;
        font-family: Noto Sans SC, Noto Sans SC;
        font-weight: 500;
        font-size: 14px;
        color: #F3AF00;
    }

    .title {
        font-family: Noto Sans SC, Noto Sans SC;
        padding-top: 32px;
        font-size: 16px;
        color: #2F3941;
        padding-bottom: 8px;
        font-weight: bold;
    }

    .text {
        padding-top: 16px;

        .text-item {
            width: 20%;

            :first-child {
                display: inline-block;
                width: 100px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #999999;
                font-size: 12px;
            }

            :last-child {
                display: inline-block;
                width: 140px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #000000;
                font-size: 12px;
                margin-right: 48px;
            }
        }
    }

    .btm {
        display: flex;
        margin-top: 24px;

        .customerbtm {
            width: 88px;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            color: #1A4CEC;
            border: 1px solid #4374FA;
            text-align: center;
            border-radius: 4px 4px 4px 4px;
            background: #FFFFFF;
            cursor: pointer;
            margin-right: 24px;
            font-weight: bold;
        }

        .customerbtmStyle {
            border: 1px solid #DCDFE5;
            color: #909399;
        }

        .bgcolor {
            background: #1A4CEC !important;
            color: #fff !important;
            width: 74px !important;
        }

        .boxwidth {
            width: 116px !important;
        }
    }
}

.content-bottom {
    height: 700px;
    background-color: #fff;
    margin-bottom: 16px;
    overflow: scroll;

    .bottom-title {
        // width: 1650px;
        margin-right: 24px;
        height: 40px;
        border-bottom: 1px solid #E4E7ED;
        margin-left: 24px;
        margin-bottom: 18px;

        .title-text {
            display: inline-block;
            font-family: PingFang SC, PingFang SC;
            width: 56px;
            height: 40px;
            line-height: 40px;
            color: #303133;
            font-size: 14px;
            font-weight: 500;
            margin-left: 20px;
            cursor: pointer;
        }

        .active-text {
            display: inline-block;
            font-family: PingFang SC, PingFang SC;
            width: 56px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            margin-left: 20px;
            cursor: pointer;
            color: #409EFF !important;
            border-bottom: 1px solid #409EFF !important;
            font-weight: 600 !important;
        }

    }

    .bottom-content {
        margin-left: 24px;

        .time {
            display: flex;
            align-items: center;

            img {
                width: 18px;
                height: 18px;
                margin-top: 8px;
            }

            .time-text {
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #303133;
                font-size: 16px;
                margin-left: 14px;
                margin-top: 8px;
            }
        }

        .serve {
            margin-right: 51px;
            margin-left: 9px;
            margin-top: 8px;
            // background-color: pink;
            padding-left: 23px;
            border-left: 1px dashed #DCDFE5;

            .serve-person {
                margin-top: 5px;
                font-family: PingFang SC, PingFang SC;
                font-size: 14px;
                color: #606266;
            }

            .box-width {
                padding-bottom: 20px;

                .serve-content {
                    background-color: #F8F8F8;
                    margin-top: 16px;
                    display: flex;

                    .SC-text {
                        padding: 20px 51px 20px 20px;
                        width: 436px;

                        .SC-img {
                            display: flex;

                            .imgList {
                                width: 64px;
                                height: 64px;
                                border-radius: 4px 4px 4px 4px;
                                margin-top: 4px;
                                margin-right: 12px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .SC-title {
                            font-family: Noto Sans SC, Noto Sans SC;
                            font-weight: 300;
                            font-size: 12px;
                            color: #2F3941;
                        }

                        .describeStyle {
                            margin-top: 4px;
                        }

                        .SC-content {
                            margin-top: 4px;
                            font-family: Noto Sans SC, Noto Sans SC;
                            font-weight: 500;
                            font-size: 14px;
                            color: #2F3941;
                        }

                        .contentStyle {
                            color: #00CE6F !important;
                        }

                        .contentStyle2 {
                            color: #E8331C !important;
                        }
                    }
                }
            }


        }
    }
}
</style>